import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { specular } from "../../../Data/CorneaRefractive";
import Popup from "../../common/Popup";

const SpecularMicroscope = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={
          "Home | Products | Cornea & Refractive | Specular Microscope"
        }
        centerHeading={"Specular Microscope"}
      />

      <section
        className="w-full  md:pt-[7.81vw] py-60px-mvw md:py-0 md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {specular.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2  gap-40px-mvw md:gap-y-0 grid-cols-1 md:pb-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[50px]  max-h-[35px] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key features :
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center  ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-full h-full object-contain"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[12vw] gap-x-16px-vw md:pt-[3vw] py-60px-mvw md:py-0  mx-auto md:flex-row flex-col gap-16px-mvw md:gap-y-0 flex items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={handleOpenModal}
                />
              </div>
            }
            {item.video &&
              item.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw]  ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>
                  <div className="w-full flex md:flex-row flex-col gap-40px-mvw md:gap-[6.25vw] items-center md:py-60px-vw">
                    {item.imggallery.map((img) => (
                      <div className=" w-full flex justify-center md:p-40px-vw items-center">
                        <img src={img} alt="" className="md:max-h-[25vw] " />
                      </div>
                    ))}
                  </div>

                  {/* <div className="md:pt-60px-vw flex flex-col items-start relative">
                    <div className="flex items-center md:gap-x-30px-vw">
                 
                    </div>
                  
                  </div> */}
                </div>
              ))}
          </div>
        ))}
      </section> 
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
        />
      )}
    </main>
  );
};

export default SpecularMicroscope;
