import React, { useEffect } from "react";
import SectionHeading from "../common/SectionHeading";
import ParaText from "../common/ParaText";
import Aos from "aos";

const AboutTopSection = ({ heading, desc, CustomCss }) => {
  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);
  return (
    <div
      className={` w-full md:px-[7.29vw] grid grid-cols-1
       md:grid-cols-2 gap-x-80px-vw`}
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className={`w-full md:py-40px-vw  ${CustomCss}`}>
        <SectionHeading title={heading} />
        <ParaText paratext={desc} CustomCss={"md:mt-40px-vw mt-20px-mvw"} />
      </div>
      <div className=" mt-40px-mvw md:mt-0 md:h-full md:w-full rounded-[5vw] md:rounded-[1.5vw]">
        <img src="/images/about/grp.png" alt="plant" />
      </div>
    </div>
  );
};

export default AboutTopSection;
