import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast"; // Import Toaster
import CategoryTopSection from "../../components/category/CategoryTopSection";
import SectionHeading from "../../components/common/SectionHeading";
import Button from "../../components/common/Button";
import Aos from "aos";
import { AiOutlineClose, AiOutlineUpload } from "react-icons/ai";

const Career = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    resume: null,
    message: "",
  });
  const fileInputRef = useRef();

  const handleRemoveFile = () => {
    // Reset file input
    fileInputRef.current.value = null;
    handleFileChange({ target: { name: "resume", files: [] } });
  };

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Restrict phone input to numbers only
    if (name === "phone" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, resume: e.target.files[0] });
  };

  const validateForm = () => {
    const newErrors = {};

    // Full Name validation
    if (!formData.fullName) newErrors.fullName = "Full Name is required.";

    // Email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    // Phone validation
    if (!formData.phone) newErrors.phone = "Phone number is required.";

    // Message validation
    if (!formData.message) newErrors.message = "Message is required.";

    // Resume validation
    if (!formData.resume) newErrors.resume = "Please upload your resume.";

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Resetting form and errors on successful submission
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      resume: null,
      message: "",
    });
    setErrors({});

    // Show success notification
    toast.success("Form submitted successfully!", {
      position: "bottom-center",
    });
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section>
      <CategoryTopSection
        breadcrumb={"Home | Careers "}
        centerHeading={"Careers"}
      />

      <div
        className="grid  grid-cols-1 text-[#2B2A29] md:px-[7.29vw] px-16px-mvw py-60px-mvw md:py-[6.25vw]"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <div className="text-center md:space-y-40px-vw space-y-20px-mvw block md:mb-60px-vw">
          <SectionHeading title={" APPLY NOW"} />
          <p className="text-20px-mvw  md:text-28px-vw">
            We continuously look for good talent. Keep us updated with your
            profiles.
          </p>
        </div>
        <div className="w-full md:flex items-center justify-center md:pl-[6.46vw] pt-40px-mvw md:pt-0">
          <form onSubmit={handleSubmit} className="w-full md:max-w-[55vw]">
            <div className="flex w-full flex-col gap-y-30px-mvw md:gap-y-60px-vw text-[#2B2A29] items-start justify-start">
              <div className="w-full">
                <label
                  htmlFor="fullName"
                  className="font-poppins text-[#2B2A29] font-medium md:text-28px-vw text-18px-mvw"
                >
                  Full Name*
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Full Name"
                />
                {errors.fullName && (
                  <p className="text-red-500">{errors.fullName}</p>
                )}
              </div>

              <div className="w-full">
                <label
                  htmlFor="email"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Email*
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Email ID"
                />
                {errors.email && <p className="text-red-500">{errors.email}</p>}
              </div>

              <div className="w-full">
                <label
                  htmlFor="phone"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Phone Number*
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Phone Number"
                />
                {errors.phone && <p className="text-red-500">{errors.phone}</p>}
              </div>

              <div className="w-full">
                <label
                  htmlFor="resume"
                  className="font-poppins text-[#2B2A29] font-medium md:text-28px-vw text-18px-mvw"
                >
                  Upload Resume*
                </label>

                <label
                  htmlFor="resume"
                  className="relative mt-[1vw] border-b-[1.5px] border-[#2B2A29] border-opacity-20 rounded-none bg-transparent w-full py-[3vw] px-[3vw] md:py-[1.2vw] md:px-[1vw] block cursor-pointer"
                >
                  <input
                    type="file"
                    name="resume"
                    id="resume"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className="hidden"
                  />
                  {formData.resume ? (
                    <div className="flex items-center justify-between">
                      <p className="mt-[0.5vw] text-[#343B3C] text-[3vw] md:text-[1.2vw]">
                        Selected file: {formData.resume.name}
                      </p>
                      <AiOutlineClose
                        onClick={handleRemoveFile}
                        className="text-[#343B3C] text-[5vw] md:text-[1.5vw] cursor-pointer"
                      />
                    </div>
                  ) : (
                 <div>
                    <img src="/icon/uploadicon.svg"
                    alt="Upload Resume"
                    className="absolute right-[2vw] md:top-[20%] -top-[30%] transform -translate-y-1
                    /2 text-[#343B3C] text-[5vw] md:text-[2.2vw]" />
                 </div>
                  )}
                </label>

                {errors.resume && (
                  <p className="text-red-500 mt-[0.5vw] text-[2vw] md:text-[1vw]">
                    {errors.resume}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="message"
                  className="font-poppins font-medium md:text-28px-vw text-18px-mvw"
                >
                  Message
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="block w-full px-0 pt-6px-mvw md:pt-20px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-22px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-22px-vw placeholder:font-regular focus:outline-none focus:ring-0"
                  placeholder="Enter Your Message"
                />
              </div>

              <div className="w-full mt-20px-mvw md:mt-0 text-center md:text-left ">
                <Button title={"Submit"} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Career;
