import { Routes, Route } from "react-router-dom";
import Home from "./Pages/home/Home";
import Header from "./components/header/Header";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import Footer from "./components/fotter/Footer";
import RetinaGlaucoma from "./Pages/categorypage/RetinaGlaucoma";
import Cataract from "./Pages/categorypage/Cataract";
import PrimaryDiagnostics from "./Pages/categorypage/PrimaryDiagnostics";
import SterilizationIndicators from "./Pages/categorypage/SterilizationIndicators";
import CorneaRefractive from "./Pages/categorypage/CorneaRefractive";
import TermsAndCondition from "./Pages/Terms&Condition/TermsAndCondition";
import PrivacyPolicy from "./Pages/Privacy&Policy/PrivacyPolicy";
import Faqs from "./Pages/faqs/Faqs";
import Contact from "./Pages/contact/Contact";
import Investors from "./Pages/investors/Investors";
import AboutUs from "./Pages/aboutus/AboutUs";
import SlitLamp from "./components/subCategory/PrimaryDiagnostics/SlitLamp";
import Lensmeter from "./components/subCategory/PrimaryDiagnostics/Lensmeter";
import SteamSterilizers from "./components/subCategory/Sterilization_Indicators/SteamSterilizers";
import Indicators from "./components/subCategory/Sterilization_Indicators/Indicators";
import PackagingMaterial from "./components/subCategory/Sterilization_Indicators/PackagingMaterial";
import UltrasoundAScan from "./components/subCategory/Cataract/UltrasoundAScan";
import OpticalBiometer from "./components/subCategory/Cataract/OpticalBiometer";
import Wavefront from "./components/subCategory/Cataract/Wavefront";
import SelectiveLaser from "./components/subCategory/Cataract/SelectiveLaser";
import Surgical from "./components/subCategory/Cataract/Surgical";
import OTTable from "./components/subCategory/Cataract/OTTable";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Oct from "./components/subCategory/RetinaGlaucoma/Oct";
import Perimeters from "./components/subCategory/RetinaGlaucoma/Perimeters";
import Tonometer from "./components/subCategory/RetinaGlaucoma/Tonometer";
import Pachymeter from "./components/subCategory/RetinaGlaucoma/Pachymeter";
import Erg from "./components/subCategory/RetinaGlaucoma/Erg";
import Bscan from "./components/subCategory/RetinaGlaucoma/Bscan";
import Scanmate from "./components/subCategory/RetinaGlaucoma/Scanmate";
import Ud from "./components/subCategory/RetinaGlaucoma/Ud";
import Funduscamera from "./components/subCategory/RetinaGlaucoma/Funduscamera";
import Topographer from "./components/subCategory/CorneaRefracative/Topographer";
import LasikLaserSystem from "./components/subCategory/CorneaRefracative/LasikLaserSystem";
import Microkeratome from "./components/subCategory/CorneaRefracative/Microkeratome";
import Asoct from "./components/subCategory/CorneaRefracative/Asoct";
import Keratoplasty from "./components/subCategory/CorneaRefracative/Keratoplasty";
import Dryice from "./components/subCategory/CorneaRefracative/Dryics";
import SpecularMicroscope from "./components/subCategory/CorneaRefracative/SpecularMicroscope";
import Ubm from "./components/subCategory/CorneaRefracative/Ubm";
import ScanmateCornea from "./components/subCategory/CorneaRefracative/ScanmateCornea";
import Udcornea from "./components/subCategory/CorneaRefracative/Udcornea";
import MetaData from "./utils/MetaData";
import PachymeterCornea from "./components/subCategory/CorneaRefracative/PachymeterCornea";
import Search from "./Pages/search/search";
import Career from "./Pages/career/Career";


function App() {
  return (
    <div
      className="m-0 p-0 overflow-x-hidden w-full h-full"
      style={{
        backgroundImage: `url("/images/home/overall_bg.png")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
      }}
    >
      <Header />
      <ScrollToTop /> 
       <MetaData/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-conditions" element={<TermsAndCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/about-us" element={<AboutUs />} /> 
        <Route path="/careers" element={<Career />} />
        {/* category routes  */}
        <Route path="/products/retina-glaucoma" element={<RetinaGlaucoma />} />
        <Route
          path="/products/cornea-refractive"
          element={<CorneaRefractive />}
        />
        <Route path="/products/cataract" element={<Cataract />} />
        <Route
          path="/products/primary-diagonostics"
          element={<PrimaryDiagnostics />}
        />
        <Route
          path="/products/sterilization-indicators"
          element={<SterilizationIndicators />}
        />
        {/* Sub-category Route  */}
        {/* PrimaryDiagnostics */}
        <Route
          path="/products/primary-diagonostics/slit-lamp"
          element={<SlitLamp />}
        />
        <Route
          path="/products/primary-diagonostics/lensmeter"
          element={<Lensmeter />}
        />
        {/* steam indicators */}
        <Route
          path="/products/sterilization-indicators/steam-sterilizers"
          element={<SteamSterilizers />}
        />
        <Route
          path="/products/sterilization-indicators/indicators"
          element={<Indicators />}
        />
        <Route
          path="/products/sterilization-indicators/packaging-material"
          element={<PackagingMaterial />}
        />
        {/* cataract */}
        <Route
          path="/products/cataract/ultrasound-a-scan"
          element={<UltrasoundAScan />}
        />
        <Route
          path="/products/cataract/optical-biometer"
          element={<OpticalBiometer />}
        />
        <Route
          path="/products/cataract/wavefront-aberrometer"
          element={<Wavefront />}
        />
        <Route
          path="/products/cataract/selective-laser-capsulotomy"
          element={<SelectiveLaser />}
        />
        <Route
          path="/products/cataract/surgical-blades"
          element={<Surgical />}
        />
        <Route path="/products/cataract/OTTable-Chair" element={<OTTable />} />
        {/* Rectina-Glucuama  */}
        <Route path="/products/retina-glaucoma/oct" element={<Oct />} />
        <Route
          path="/products/retina-glaucoma/perimeters"
          element={<Perimeters />}
        />
        <Route
          path="/products/retina-glaucoma/tonometer"
          element={<Tonometer />}
        />
        <Route
          path="/products/retina-glaucoma/pachymeter"
          element={<Pachymeter />}
        />
        <Route path="/products/retina-glaucoma/erg" element={<Erg />} />
        <Route path="/products/retina-glaucoma/b-scan" element={<Bscan />} />
        <Route
          path="/products/retina-glaucoma/scanmate-flex"
          element={<Scanmate />}
        />
        <Route path="/products/retina-glaucoma/ud-800" element={<Ud />} />
        <Route
          path="/products/retina-glaucoma/fundus-camera"
          element={<Funduscamera />}
        />
        {/* Cornea-Refractive  */}
        <Route
          path="/products/cornea-refractive/topographer"
          element={<Topographer />}
        />
        <Route
          path="/products/cornea-refractive/lasik-laser-system"
          element={<LasikLaserSystem />}
        />
        <Route
          path="/products/cornea-refractive/microkeratome"
          element={<Microkeratome />}
        />
        <Route
          path="/products/cornea-refractive/pachymeter"
          element={<PachymeterCornea/>}
        />
        <Route path="/products/cornea-refractive/as-oct" element={<Asoct />} />
        <Route
          path="/products/cornea-refractive/keratoplasty"
          element={<Keratoplasty />}
        />
        <Route
          path="/products/cornea-refractive/dry-ice"
          element={<Dryice />}
        />
        <Route
          path="/products/cornea-refractive/specular-microscope"
          element={<SpecularMicroscope />}
        />
        <Route path="/products/cornea-refractive/ubm" element={<Ubm />} />{" "}
        <Route
          path="/products/cornea-refractive/scanmate-flex"
          element={<ScanmateCornea />}
        />
        <Route
          path="/products/cornea-refractive/ud-800"
          element={<Udcornea />}
        />
        <Route
          path="/search"
          element={<Search />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
