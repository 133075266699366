import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { selectiveLaser } from "../../../Data/Cataract";
import Popup from "../../common/Popup";

const SelectiveLaser = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cataract | Selective Laser Capsulotomy"}
        centerHeading={"Selective Laser Capsulotomy"}
      />

      <section
        className="  md:py-[7.81vw] py-60px-mvw md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {selectiveLaser.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 gap-y-40px-mvw md:gap-y-0 grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[50px]  max-h-[35px] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain block"
                  />
                </div>

                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw max-w-[70%]">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  KEY FEATURES:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center   ">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-auto max-h-full object-contain"
                  />
                ))}
              </div>
            </div>
            {item.advantage &&
              item.advantage.map((data) => (
                <div className="w-full md:pt-0 pt-60px-mvw">
                  <h1 className="font-raleway font-bold text-24px-mvw md:text-32px-vw text-[#2B2A29]  text-center md:my-20px-vw">
                    {data.name}
                  </h1>

                  <div className="w-full gap-x-80px-vw gap-y-40px-mvw md:gap-y-0 flex-col md:flex-row flex  justify-center md:items-start items-center  pt-12px-mvw md:pt-0">
                    {data.points.map((item) => (
                      <div
                        key={item.id}
                        className="flex flex-col md:py-[3vw]  gap-y-35px-vw items-center"
                      >
                        <div className="bg-[#519A7F] text-center p-8px-vw rounded-full flex-shrink-0">
                          <img
                            src={item.img}
                            alt={`advantage-${item.id}`}
                            className="md:w-96px-vw h-auto"
                          />
                        </div>

                        <p className="text-center text-[#2B2A29] opacity-80 text-16px-mvw md:text-20px-vw font-poppins font-regular">
                          {item.desc}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

            {
              <div className="w-full  gap-x-16px-vw md:pt-[3vw] py-60px-mvw md:py-0 mx-auto md:flex-row flex-col gap-y-16px-mvw md:gap-y-0 flex items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={handleOpenModal}
                />
              </div>
            }
            {/* {item.video &&
              item.video.map((videoItem, index) => (
                <div
                  key={index}
                  className="w-full flex flex-col items-center justify-center md:pt-[6.25vw]"
                >
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    IMAGE & VIDEO GALLERY
                  </h1>
                  <div className="md:mt-60px-vw flex flex-col items-start relative">
                  
                    {!isPlaying ? (
                      <>
                        <img
                          src={videoItem.videoSrc} 
                          alt="video thumbnail"
                          className="md:max-w-[42.24vw] block"
                        />
                        <div
                          className="absolute cursor-pointer inset-0 flex items-center justify-center"
                          onClick={handlePlay} 
                        >
                          <img
                            src="/icon/playIcon.png" 
                            alt="play icon"
                            className="md:w-73px-vw"
                          />
                        </div>
                      </>
                    ) : (
                    
                      <iframe
                        className="md:max-w-[80vw] block"
                        width="700" // Increase the width here
                        height="450" // Adjust the height accordingly
                        src={`https://www.youtube.com/embed/${videoItem.videoUrl
                          .split("/")
                          .pop()}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    )}

                    <div className="md:pt-20px-vw pt-12px-mvw  text-[#2B2A29] font-poppins font-medium md:text-24px-vw text-16px-mvw">
                      {videoItem.videoTitle}
                    </div>
                  </div>
                </div>
              ))} */}
          </div>
        ))}
      </section> 
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
        />
      )}
    </main>
  );
};

export default SelectiveLaser;
