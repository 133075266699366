import React, { useEffect, useState } from "react";
import Marquee from "react-marquee-slider"; // Ensure you have this library installed
import SectionHeading from "../common/SectionHeading";
import Aos from "aos";
import { ourpaternes, partnerslogomobile } from "../../Data/DummyData";

const OurPartners = ({ heading, CustomCss }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [velocity, setVelocity] = useState(20);
  

  useEffect(() => {
    Aos.init({
      easing: "ease-in-out",
      duration: 1000,
      once: false,
      offset: 100,
    });
  }, []); 


  useEffect(() => {
    const adjustSpeed = () => {
      const screenWidth = window.innerWidth;
      const referenceWidth = 1920; // Baseline width for desktop
      const baseSpeed = 20; // Speed for reference width

      // Scale velocity relative to screen width
      const scaledSpeed = (screenWidth / referenceWidth) * baseSpeed;

      setVelocity(scaledSpeed);
    };

    adjustSpeed(); // Set initial speed
    window.addEventListener("resize", adjustSpeed); // Adjust on window resize

    return () => {
      window.removeEventListener("resize", adjustSpeed); // Cleanup
    };
  }, []);

  return (
    <section
      className={`w-full relative grid grid-cols-1 pt-20px-mvw  md:pt-0 place-items-center pl-88px-vw pr-62px-vw ${CustomCss}`}
      data-aos="fade-up"
      data-aos-duration="1500"
      data-aos-offset="150"
    >
      <div className="text-center">
        <SectionHeading
          title={heading}
          customCss={"uppercase my-20px-mvw md:my-80px-vh"}
        />
      </div>
      <div
        className="relative flex flex-col md:flex-row  items-center justify-center overflow-hidden 
    md:py-65px-vh py-35px-mvw bg-opacity-20 z-10 bg-white rounded-[4vw] 
    md:rounded-[1.04vw] h-full"
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <Marquee
          velocity={velocity}
          minScale={0.8}
          className="partner-marquee flex items-center"
          pauseOnHover={isPaused}
        >
          {ourpaternes.map((item) => (
            <div
              key={item.id}
              className="flex justify-center items-center md:max-w-[11.56vw] md:max-h-[7vw]  md:!mr-[6.25vw] last:mr-0 h-full"
            >
              <img
                src={item.imgsrc}
                alt={`Partner ${item.id}`}
                className="w-auto mr-32px-mvw md:h-[10vh] h-[3vh] object-contain opacity-60 filter grayscale  "
              />
            </div>
          ))}
        </Marquee>

        <div className="md:hidden block mt-32px-mvw">
          <Marquee
            velocity={velocity}
            minScale={0.8}
            direction="left"
            className="partner-marquee flex items-center"
          >
            {ourpaternes.map((item) => (
              <div key={item.id} >
                <img
                  src={item.imgsrc}
                  alt={`Partner ${item.id}`}
                  className="mr-32px-mvw h-[3vh] object-contain opacity-100 filter grayscale"
                />
              </div>
            ))}
          </Marquee>
        </div>
      </div>

      <div className="absolute md:-left-[2.5vw] left-[-40vw] blur-[25px] opacity-70 -bottom-[18vh] md:-bottom-[13vh]">
        <img src="/images/home/eclipse.svg" alt="eclipse" />
      </div>
      <div className="absolute hidden md:block -right-50px-vw blur-[40px] opacity-70 bottom-40px-vw">
        <img src="/images/home/eclipse.svg" alt="eclipse" />
      </div>
    </section>
  );
};

export default OurPartners;
