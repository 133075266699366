import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";
import ImageSlider from "../../slider/ImageSlider";
import Aos from "aos";
import { keratoplasty } from "../../../Data/CorneaRefractive";
import Marquee from "react-marquee-slider";
import BottomSlider from "../../slider/BottomSlider";
import CategorySelector from "../../category/CategorySelector";
import Popup from "../../common/Popup";

const Keratoplasty = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const activeCategory = keratoplasty[activeIndex];
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Keratoplasty"}
        centerHeading={"Keratoplasty"}
      />

      <section
        className="w-full px-16px-mvw md:px-0 md:py-[7.81vw] py-60px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={keratoplasty}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory?.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2  md:px-[7.29vw] py-30px-mvw gap-y-40px-mvw md:gap-y-0 grid-cols-1 md:py-80px-vw">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[50px]  max-h-[35px] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw md:max-w-[95%] text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  Key features:
                </div>
                {item?.main_points?.map((item) => (
                  <div className="w-full flex items-start gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] flex-shrink-0 md:mt-[0.6vw] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center  ">
                {item?.img?.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-auto md:max-h-full object-cover"
                  />
                ))}
              </div>
            </div>

            {
              <div className="w-full  md:px-[7.29vw]  gap-x-16px-vw md:pt-[3vw] py-60px-mvw md:py-0 mx-auto md:flex-row flex-col gap-y-16px-mvw md:gap-y-0 flex  items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={handleOpenModal}
                />
              </div>
            }
            {/* {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                 
                  <ImageSlider data={item.imggallery} />

                 
                  <div className="absolute left-0 md:w-[87%]  md:mx-[7vw] md:mb-40px-vw border-b-2 opacity-10 border-[#2B2A29] z-10"></div>
                
                  {item?.videoData.length > 2 ? (
                    <BottomSlider slides={item.videoData} />
                  ) : (
                    <div
                      className="flex md:px-[7.29vw] items-start  md:pt-40px-vw
                      justify-start gap-x-80px-vw"
                    >
                      {item?.videoData.map((videoItem, index) => (
                        <div
                          key={index}
                          className="mb-8 md:mt-60px-vw flex flex-col items-start"
                        >
                          {" "}
                          <div className="bg-gray-400 rounded-[1.04vw] h-[30vw] w-[42vw] ">
                          
                          </div>
                          <div className="md:mt-15px-vw mt-12px-mvw text-[#2B2A29] font-poppins font-medium md:text-24px-vw text-16px-mvw">
                            {videoItem?.title}
                          </div>
                          <div className="md:pt-12px-vw text-[#2B2A29] font-poppins font-reguler text-14px-mvw md:text-20px-vw">
                            {videoItem.description}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))} */}
          </div>
        ))}
      </section> 
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
        />
      )}
    </main>
  );
};

export default Keratoplasty;
