import React, { useEffect } from "react";
import CategoryTopSection from "../../components/category/CategoryTopSection";
import Aos from "aos";

const TermsAndCondition = () => {
  const textArray = [
    "The following Terms and Conditions will be deemed to have been accepted by the User on usage of the website My Healthskape. The user is requested to read them carefully before you use the services of this site.",

    "1. The term User shall refer to the user who is browsing the site. The term My Healthskape shall refer to My Healthskape Medicals Pvt. Ltd.  The term site/website refers to My Healthskape owned and monitored by My Healthskape.",

    "2. By using the Site, the users agree to follow and be bound by the following Terms and Conditions concerning the user’s use of the Site. My Healthskape may revise the Terms of Use at any time without notice.",

    "3. My Healthskape may terminate User's access at any time for any reason.",

    "4. All content present on this site is the exclusive property of My Healthskape.",

    "5. My Healthskape may change or discontinue any aspect of its website at any time, including, its content or features.",

    "6. User agrees to indemnify, defend and hold My Healthskape harmless from and against all losses, expenses, damages and costs, including reasonable attorneys' fees, arising out of or relating to any misuse by the User of the content and services provided on the Site.",
  ];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="w-full">
      <CategoryTopSection
        breadcrumb={"Home | Terms & Conditions"}
        centerHeading={"Terms & Conditions"}
      />

      <div
        className="w-full md:px-[7.29vw] px-16px-mvw py-60px-mvw md:py-[4vw] "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {textArray.map((line, index) => (
          <p
            key={index}
            className="text-[#2B2A29] font-poppins font-regular  md:text-20px-vw text-16px-mvw pb-40px-mvw md:pb-40px-vw opacity-80"
          >
            {line}
          </p>
        ))}
      </div>
    </section>
  );
};

export default TermsAndCondition;
