import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import BottomSlider from "../../slider/BottomSlider";
import { scanmatecornea } from "../../../Data/CorneaRefractive";
import Popup from "../../common/Popup";

const ScanmateCornea = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Cornea & Refractive | Scanmate Flex"}
        centerHeading={"Scanmate Flex"}
      />

      <section
        className="w-full  md:py-[7.81vw] pt-60px-mvw  md:px-[7.29vw] px-16px-mvw"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        {scanmatecornea.map((item) => (
          <div key={item.id} className="w-full">
            <div
              className="grid md:grid-cols-2 gap-y-40px-mvw
             md:gap-x-[6vw] grid-cols-1 md:pb-80px-vw"
            >
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw  md:gap-y-[1.5vw]">
                <div className="md:max-w-full md:max-h-[50px]  max-h-[35px] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  KEY FEATURES:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center gap-x-80px-vw  ">
                {item.img.map((img, index) => (
                  <div key={index} className=" ">
                    <img
                      src={img}
                      alt=""
                      className="w-full  h-full object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>

            {
              <div className="w-full md:px-[12vw] gap-x-16px-vw md:pt-[3vw]  flex-col md:flex-row py-60px-mvw md:py-0 gap-16px-mvw md:gap-y-0 mx-auto flex items-center justify-center">
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={handleOpenModal}
                />
              </div>
            }
            {item.video &&
              item.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>
                  <div className="w-full flex md:px-[7.29vw] gap-y-40px-mvw md:gap-y-0 flex-col border-none border-b-2 border-opacity-10  border-[#2B2A29] z-10 md:flex-row items-center md:py-60px-vw">
                    {item.imggallery.map((img) => (
                      <div className=" w-full  flex justify-center md:p-0 items-center">
                        <img src={img} alt="" className="md:max-w-[25vw]" />
                      </div>
                    ))}
                  </div>

                  {/* <div className="absolute left-0 md:w-full  md:mt-40px-vw   border-b-2 opacity-10  border-[#2B2A29] z-10"></div> */}

                  {/* <div className="md:pt-60px-vw flex flex-col items-start relative">
                    <div className="flex items-center md:gap-x-30px-vw">
                      <div className="relative">
                        <img
                          src={item.imggallery}
                          alt="imggallery"
                          className="md:p-40px-vw w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 rounded-[1.5vw] bg-[#2B2A29] opacity-60"></div>
                        <div className="absolute inset-0  flex w-full h-full items-center justify-center ">
                          <div className=" border border-solid rounded-full border-white p-30px-vw">
                            <BsArrowLeft className="md:text-[2vw] text-white" />
                          </div>
                        </div>
                      </div>

                      {item.videoData && (
                        <div className="pt-60px-mvw md:pt-0">
                          <BottomSlider slides={item.videoData} />
                        </div>
                      )}
                    
                    </div>
                  
                  </div> */}
                </div>
              ))}
          </div>
        ))}
      </section> 
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
        />
      )}
    </main>
  );
};

export default ScanmateCornea;
