import React, { useEffect, useState } from "react";
import Button from "./Button";

import Popup from "./Popup";

const DownloadCard = ({ data, onOpenModal }) => {

  return (
    <>
      {data.map((item) => (
        <div
          key={item.id}
          className="md:w-[27.92vw] w-full relative   overflow-hidden cursor-pointer border-[1px] border-solid hover:border-none border-[#707070] group border-opacity-40 rounded-[5vw] md:rounded-[1.6vw] "
        >
          <div
            className="absolute inset-0 bg-cover bg-center opacity-0 group-hover:opacity-100  transition-opacity duration-300"
            style={{ backgroundImage: "url('/images/investors/card-bg.png')" }}
          />
          <div className="flex flex-col text-[#2B2A29] font-poppins group-hover:text-white items-start md:p-40px-vw p-26px-mvw md:gap-y-12px-vw ">
            {/* <div className="font-medium md:text-32px-vw text-18px-mvw group-hover:text-white z-10">
              0{item.id}
            </div> */}
            <div className="font-poppins font-medium md:text-32px-vw text-18px-mvw group-hover:text-white z-[4] ">
              {item.title}
            </div>
            <div className="md:mt-40px-vw mt-40px-mvw">
              {/* Button that triggers PDF download */}
              <Button
                title={"Download PDF"}
                className="border-[#707070]"
                customClasses={
                  "group-hover:border-white transition-colors duration-300"
                }
                onClick={() => onOpenModal(item.data)}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default DownloadCard;
