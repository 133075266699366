import React from "react";
import { Link } from "react-router-dom";

const ListingCards = ({ data }) => { 
  return (
    <main className="w-full grid md:grid-cols-3 justify-center grid-cols-1 mb-80px-mvw md:mb-0 mt-32px-mvw  md:my-80px-vh md:gap-x-20px-vw md:gap-y-34px-vw md:pb-[7vw] gap-20px-mvw px-16px-mvw md:px-[7.29vw] ">
      {data.map((item, index) => (
        <Link key={index} to={item.link} className="">
          <div className="md:border-[#707070] h-full md:max-h-full md:pb-25px-vw  w-full relative hover:border-none border-[1px] md:border-solid md:border-opacity-40 rounded-[5.33vw] md:rounded-[1.04vw] bg-white/20  backdrop-blur-[50px]  md:bg-opacity-100 md:bg-none flex flex-col items-start justify-between font-poppins font-medium space-y-12px-vh  md:text-32px-vw text-18px-mvw md:p-40px-vw p-26px-mvw transition-all duration-300 cursor-pointer hover:bg-white  ">
            <div className="flex w-full items-start justify-between">
              <div>
                {/* <div>{item.id}</div> */}
                <div className="text-nowrap">{item.name}</div>
              </div>
              <div className="md:max-w-[10vw] max-w-[40%]">
                <img src={item.logo} alt="" className="w-full h-full object-cover " />
              </div>
            </div>

            <div className="md:max-h-[25.04vw] w-full flex items-center justify-center">
              <img
                src={item.img}
                alt=""
                className="object-contain w-full h-full "
              />
            </div>

            {/* Arrow hidden initially, visible on hover */}
            <div className="absolute  bottom-0 right-0 opacity-0 hover:opacity-100 transition-opacity duration-300">
              <img
                src="/images/category/active_arrow.svg"
                alt="arrow"
                loading="lazy" 
              
              />
              <div className="absolute right-20px-vh bottom-36px-vh">
                <img
                  src="/images/category/arrow.png"
                  alt="arrow"
                  loading="lazy" 
                  className="md:max-w-50px-vw h-auto object-cover"
                />
              </div>
            </div>
          </div>
        </Link>
      ))}
    </main>
  );
};

export default ListingCards;
