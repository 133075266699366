export const topographer = [
  {
    id: 1,
    model: "ANTARES +",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "ANTARES + <br> Corneal Topographer",
        // name: "Treatment Table",
        main_points: [
          "Various maps",
          "Keratoconus screening",
          "Videokeratoscopy",
          "Pupillography",
          "Contact lens application",
          "Dry eye report",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/antares+.pdf",
          },
        ],
        img: ["/images/cornea/ant1.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/Topographer/Antares/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Contact Lens Application",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Dry Eye Report",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Keratoconus Screening",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Pupillography",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Antares/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Videokeratoscope",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Contact Lens Application",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "SIRIUS +",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "SIRIUS +",
        name: "Scheimpflug with Placido disk Topography",
        main_points: [
          "Measurements up to 100 HR corneal sections.",
          "Various maps (Front & Back of the cornea)",
          "Early Keratoconus screening with ABCD grading scale",
          "Ambrosio pachymetry progression map",
          "Corneal aberrometer",
          "Glaucoma screening",
          "Pupillography",
          "IOL calculation with Ray tracing",
          "Intrastromal ring",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/sirius+.pdf",
          },
        ],
        img: ["/images/cornea/ant2.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoSrc: "/images/cataract/overview.png",
            videoTitle: "Compact Setup",
            videoData: [
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Corneal Aberrometery",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Glaucoma Screening",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "IOL Calculation",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capable.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "IRCS",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Keratoconus Screening",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
              {
                videothumbnail: "/Cornea2/Topographer/Sirius/6.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Pupillography",
                // videoDesc:
                //   "Capture high-resolution 45° true color retinal imaging. The REVO FC OCT series adds a 12.3 MP Fundus Camera, capableeeee.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    model: "TMS-4N",
    data: [
      {
        id: 1,
        logo: "/images/cataract/TOMEY.png",
        model: "TMS-4N",
        name: "Corneal Topographer",
        main_points: [
          "Built-in LCD screen",
          "Various maps",
          "Keratoconus screening",
          "Fourier analysis",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/tms-4n.pdf",
          },
        ],
        img: ["/images/cornea/ant3.png"],
      },
    ],
  },
];

export const microkeratome = [
  {
    id: 1,
    logo: "/images/refractive/logo-moria-white.png",
    model: "Moria Evo 3e console with SBK set",
    //   name: "Osiris-T provides a corneal topographer combined with a total ocular aberrometer",
    main_points: [
      "Fastest flap creation ( 4 sec)",
      "Predictable thin and Planer flap",
      "Excellent stromal surface smoothness",
      "Excellent safety profile",
      "Very fast visual recovery",
      "Excellent quality of vision",
    ],
    additionalInfo: [
    
      {
        id: 2,
        title: "Brochures",
      },
    ],
    img: ["/images/cornea/mor1.png"],
    video: [
      {
        imggallery: ["/images/refractive/Scroll Group 1.png"],
        videoSrc: "/images/cornea/corneavideoimg.png",
        videoTitle: "SBK Surgical Process",
        videoUrl: "/Cornea2/Microkeratome/v1.wmv",
      },
    ],
    images: [
      "/Cornea2/Microkeratome/1.png",
      "/Cornea2/Microkeratome/2.png",
      "/Cornea2/Microkeratome/3.png",
      "/Cornea2/Microkeratome/5.png",
      "/Cornea2/Microkeratome/6.png",
      "/Cornea2/Microkeratome/7.png",
      "/Cornea2/Microkeratome/8.png",
      "/Cornea2/Microkeratome/9.png",
      "/Cornea2/Microkeratome/11.png",
      "/Cornea2/Microkeratome/12.png",
      "/Cornea2/Microkeratome/13.png",
      "/Cornea2/Microkeratome/14.png",
      "/Cornea2/Microkeratome/15.png",
    ],
  },
];

export const asoct = [
  {
    id: 1,
    model: "MS-39",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "MS-39 ",
        name: "AS-OCT",
        main_points: [
          "HR corneal sections images on a diameter of 16 mm",
          "Epithelial & stromal map",
          "Early Keratoconus screening with ABCD grading",
          "Corneal aberrometer",
          "Crystalline biometery",
          "Pupillography",
          "IOL calculation with Ray tracing",
          "Intrastromal ring",
          "Advanced analysis of Tear film",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/ms-39.pdf",
          },
        ],
        img: ["/images/cornea/asimg.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Corneal Aberrometry",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Corneal Sections",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Crustalline Biometry",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Epithelium Thickness",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/MS39/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Keratoconus Summary",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "MS-39 Techinical Features",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "CASIA2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/csologo.png",
        model: "CASIA2",
        name: "AS-OCT",
        main_points: [
          "Application for cataract/glaucoma/cornea surgery",
          "Epithelium mapping",
          "Glaucoma angle analysis",
          "Advanced imaging at high resolution and deep scanning depth (13 mm)",
          "Lens shape analysis and trend analysis",
          "Phakic IOL simulation",
          "Pre- and Post-Op ICL function",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/casia2.pdf",
          },
        ],
        img: ["/images/cornea/asimg1.png"],
        video: [
          {
            imggallery: [
              "/images/cataract/imggallery_1.png",
              "/images/cataract/imggallery_2.png",
              "/images/cataract/imggallery_3.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "HR Raster Scan",
              },

              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Lens Analysis",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Post Op Cataract Application",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Pre Op Cataract Application",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "STAR 360 Degree Application",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/6.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "STAR 360 Degree Detailed Report",
              },
              {
                videothumbnail: "/Cornea2/ASOCT/CASIA2/7.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Trend Analysis",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const keratoplasty = [
  {
    id: 1,
    model: "VACUUM TREPHINE & PUNCH",
    data: [
      {
        id: 1,
        logo: "/images/cornea/MORIA.png",
        model: "Vacuum Trephine & Punch for PK & DALK Procedures",
        // name: "Treatment Table",
        main_points: [
          "A precise and centred positioning",
          "A vertical cut",
          "Ergonomic design",
          "Adjustable & Non-adjustable depth",
          "Controlled and safe",
          "2-piece design with an Ultra-sharp pre-loaded blade",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
          },
        ],
        img: ["/images/cornea/kerimg.png"],
        video: [
          {
            imggallery: [
              "/images/refractive/Vacuum-punch.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Adjustable-vacuum-Trephine.png",
              "/images/refractive/Vacuum-punch.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/11.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
              },
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/12.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundussss Camera",
              },
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/13.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
              {
                videothumbnail: "/Cornea2/Keratoplasty/DSAEKALTKSystem/14.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Non-Myd Color Fundus Camera",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "DSAEK + ALTK SYSTEM",
    data: [
      {
        id: 1,
        logo: "/images/cornea/MORIA.png",
        model: "Moria Evo 3e console with DSAEK + ALTK  set ",
        // name: "Treatment Table",
        main_points: [
          "Linear system",
          "High degree of accuracy & reproducibility",
          "Range of pre-calibrated single use heads",
          "For Ultra-Thin DSAEK , as well as conventional DSAEK",
          "Very fast visual recovery",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
          },
        ],
        img: ["/images/cornea/kerimg1.png"],
        video: [
          {
            imggallery: [
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/11.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/12.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/13.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/14.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/15.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/16.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/17.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/18.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/19.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/20.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/21.png",
              "/Cornea2/Keratoplasty/DSAEKALTKSystem/22.png",
            ],
            videoData: [
              {
                videothumbnail: "",
                title: "CBM ALTK+DSAEK System",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                videothumbnail: "",
                title: "UT DSAEK Single Pass Short",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/images/refractive/Crustalline biometery.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const dryice = [
  {
    id: 1,
    model: "IDRA",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "IDRA",
        name: "Dry Eye Diagnosis",
        main_points: [
          "Quick-Accurate- Non-invasive",
          "15 sec of acquisition in fully automatic mode",
          "Integrated and easy to use",
          "Evaluation of all tear film layers",
          // "Optimal working conditions for the surgeon",
          // "Robust steel chassis with four double castors",
          // "IOL calculation with Ray tracing",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/idra.pdf",
          },
        ],
        img: [
          "/images/cornea/dry.png",
          // "/images/refractive/Adjustable-vacuum-Trephine.png",
        ],
        video: [
          {
            imggallery: [
              "/Cornea2/DryEye/IDRA/11.png",
              "/Cornea2/DryEye/IDRA/12.png",
              "/Cornea2/DryEye/IDRA/13.png",
              "/Cornea2/DryEye/IDRA/14.png",
              "/Cornea2/DryEye/IDRA/15.png",
            ],

            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/IDRA/2.png",
                title: "Product Application",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                videothumbnail: "/Cornea2/DryEye/IDRA/1.png",
                title: "Quick-Accurate- Non-invasive",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/Cornea2/DryEye/IDRA/1.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "ACTIVA",
    data: [
      {
        id: 1,
        logo: "/images/refractive/SBM.png",
        model: "ACTIVA",
        name: "Dry Eye treatment device",
        main_points: [
          "Safe and effective",
          "Long lasting relief",
          "Innovative eye mask",
          "Fast and painless",
          "Easily sanitized and no gel required",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/activa.pdf",
          },
        ],
        img: ["/images/cornea/active.png"],
        video: [
          {
            imggallery: [
              "/images/cornea/active(1).png",
              "/images/refractive/Activa_2.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/Activa/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Activa/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Activa/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "",
              },
            ],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  {
    id: 3,
    model: "TEARCHECK",
    data: [
      {
        id: 1,
        logo: "/images/cornea/ESW.png",
        model: "TEARCHECK",
        name: "Dry Eye Diagnosis",
        main_points: [
          "Innovative",
          "9 exams for smart analysis",
          "Simple and Fast",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/tearcheck.pdf",
          },
        ],
        img: ["/images/cornea/tear.png"],
        video: [
          {
            imggallery: [
              "/Cornea2/DryEye/Tearcheck/11.png",
              "/Cornea2/DryEye/Tearcheck/12.png",
              "/Cornea2/DryEye/Tearcheck/13.png",
              "/Cornea2/DryEye/Tearcheck/14.png",
              "/Cornea2/DryEye/Tearcheck/15.png",
            ],
            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0307",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0240_compressed",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0193",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/4.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0163",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/5.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC0149",
              },
              {
                videothumbnail: "/Cornea2/DryEye/Tearcheck/6.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "_DSC-1",
              },
            ],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },

  {
    id: 4,
    model: "E-EYE",
    data: [
      {
        id: 1,
        logo: "/images/cornea/ESW.png",
        model: "E-EYE",
        name: "Dry Eye treatment device",
        main_points: [
          "IRPL technology",
          "Simple and Fast",
          "Instant improvement",
          "Long lasting",
          "Quantified efficiency",
          "Safe & tailor-made",
        ],
        additionalInfo: [
          {
            id: 2,
            title: "Brochures",
            data: "/pdf/brochures/e-eye.pdf",
          },
        ],
        img: ["/images/cornea/eye.png"],
        video: [
          {
            imggallery: ["/Cornea2/DryEye/EEye/11.png"],
            videoSrc: "/images/refractive/_DSC0149.png",
            videoData: [
              {
                videothumbnail: "/Cornea2/DryEye/EEye/1.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "11",
              },
              {
                videothumbnail: "/Cornea2/DryEye/EEye/2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "13",
              },
              {
                videothumbnail: "/Cornea2/DryEye/EEye/3.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Mechanism-of-Action",
              },
            ],
            // videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const specular = [
  {
    id: 1,
    logo: "/images/cornea/TOMEY.png",
    model: "EM-4000",
    name: "Specular Microscope",
    main_points: [
      "Fully automatic with touch screen",
      "13 measurement area",
      "L-count, Trace, Core method, Dark Area analysis",
      "Counts more than 300 cells",
      "Integrated Non-contact pachy",
    ],
    additionalInfo: [
   
      {
        id: 1,
        title: "Brochures",
        data: "/pdf/brochures/em-4000.pdf",
      },
    ],
    img: ["/images/cornea/specular.png"],
    video: [
      {
        imggallery: [
          "/images/refractive/_0-3-EM_4000_3_a.png",
          "/images/refractive/EM_4000_2.png",
          "/images/refractive/EM_4000_3.png",
        ],
        // videoSrc: "/images/cataract/overview.png",
        // videoTitle: "Upgradable to A-scan and UBM",
      },
    ],
  },
];

export const pachymeter = [
  {
    id: 1,
    model: "Pachette 4",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "PACHETTE 4",
        name: "Gold standard in in desktop Pachymetry",
        main_points: [
          "Easy , Fast and Accurate ",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
        ],
        img: ["/images/rectina/Pachette 4.png"],
        video: [
          {
            imggallery: ["/images/rectina/Pachette 4(1).png"],

            videoData: [
              {
                videothumbnail: "/images/cornea/corneaimg.png",
                title: "Flap-Mode-for-Pachette-4n",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
              {
                videothumbnail: "/images/cornea/corneaimg2.png",
                title: "Turn-on-Power-Pachette-4",
                description:
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              },
            ],
            videoSrc: "/Cornea2/DryEye/IDRA/1.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    model: "Pachmate 2",
    data: [
      {
        id: 1,
        logo: "/images/rectina/DGH.png",
        model: "PACHMATE 2",
        name: "Handheld Pachymetry",
        main_points: [
          "Easy , Fast and Accurate",
          "Store up to 25 measurements",
          "Mapping mode",
          "Battery operated",
        ],
        additionalInfo: [
          {
            id: 1,
            title: "Brochures",
          },
        ],
        img: ["/images/rectina/Pachmate-2.png"],

        video: [
          {
            imggallery: ["/images/rectina/Pachmate-2(1).png"],

            videoData: [
              {
                videothumbnail:
                  "/images/rectina/Proven-Accuracy-Pachmate-2.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Proven-Accuracy-Pachmate-2",
              },
              {
                videothumbnail: "/images/rectina/Rotation Probe.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "Rotation Probe",
              },
              {
                videothumbnail: "/images/rectina/User Friendly.png",
                videoUrl: "https://youtu.be/CuPHd0V9cgI",
                videoTitle: "User Friendly",
              },
            ],
            videoSrc: "/Cornea2/DryEye/IDRA/1.png",
            videoTitle: "Crustalline Biometery",
          },
        ],
      },
    ],
  },
];

export const scanmatecornea = [
  {
    id: 1,
    logo: "/images/rectina/DGH.png",
    model: "SCANMATE FLEX",
    name: "The Scanmate Flex is a portable ophthalmic ultrasound device. It is the perfect product to enhance your diagnostic practice.",
    main_points: [
      "Portable and USB connectivity",
      "Provides clear imaging of the posterior segment of the eye",
      "Seamless platform",
      "Upgradable to A-scan and UBM",
    ],
    additionalInfo: [
     
      {
        id: 1,
        title: "Brochures",
        data: "/pdf/brochures/scanmate-flex.pdf",
      },
    ],
    img: ["/images/cornea/ubmimg.png"],
    video: [
      {
        imggallery: [
          "/images/cornea/tubeimg.png",
          "/images/cornea/ubmimg(1).png",
        ],
        videoSrc: "/images/rectina/Scanmate FLEX.png",
        videoTitle: "Upgradable to A-scan and UBM",
        videoData: [
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/1.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Cameraaaaaaaaa",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/2.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundussss Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/3.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/4.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
          {
            videothumbnail: "/Cornea2/UBM/ScanmateFlex/5.png",
            videoUrl: "https://youtu.be/CuPHd0V9cgI",
            videoTitle: "Non-Myd Color Fundus Camera",
          },
        ],
      },
    ],
  },
];
