import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Marquee from "react-marquee-slider";

const CustomArrow = ({ direction, onClick }) => (
  <button
    className={`absolute z-10 md:w-[91px] w-[40px] h-[40px] md:h-[91px] flex items-center justify-center bg-white rounded-full focus:outline-none ${
      direction === "left"
        ? "md:left-[73.61vw] left-[0vw]"
        : "md:right-[7.29vw] right-[0vw]"
    } md:top-[-8vw] top-[40vw]`}
    onClick={onClick}
    aria-label={direction === "left" ? "Previous slide" : "Next slide"}
  >
    <div
      className="absolute inset-0 bg-cover bg-center"
      style={{
        backgroundImage: `url(${
          direction === "left"
            ? "/icon/leftsliderarrow.svg"
            : "/icon/rightsliderarrow.svg"
        })`,
      }}
    ></div>
    <div className="absolute z-20">
      {direction === "left" ? (
        <HiChevronLeft className="text-black w-[6vw] h-[6vw]" />
      ) : (
        <HiChevronRight className="text-black w-[6vw] h-[6vw]" />
      )}
    </div>
  </button>
);

const ImageSlider = ({ data }) => {
  const [isPaused, setIsPaused] = useState(false);
  const [velocity, setVelocity] = useState(30);

  useEffect(() => {
    const calculateVelocity = () => {
      const baseVelocity = 200;
      const numLogos = data.length;
      const screenFactor = window.innerWidth / 1920;
      const adjustedSpeed = baseVelocity / numLogos;
      return Math.max(8, adjustedSpeed * screenFactor);
    };

    const handleResize = () => {
      setVelocity(calculateVelocity());
    };

    handleResize(); // Calculate velocity on mount
    window.addEventListener("resize", handleResize); // Recalculate velocity on resize

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data.length]);

  const slickSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // centerMode: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    nextArrow: <CustomArrow direction="right" />,
    prevArrow: <CustomArrow direction="left" />,
  };

  return (
    <div
      className="w-full  md:pt-90px-vw md:py-0 py-40px-mvw   px-16px-vw md:px-0 "
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* For Mobile: react-slick Slider */}
      {/* <div className="md:hidden w-full gap-y-60px-mvw md:gap-y-0">
        <Slider {...slickSettings}>
          {data.map((imgSrc, index) => (
            <div
              key={index}
              className="w-full h-[85vw]  flex justify-center items-center"
            >
              <img
                src={imgSrc}
                alt={`imgGallery-${index}`}
                className="max-w-full max-h-full object-contain"
              />
            </div>
          ))}
        </Slider>
      </div> */}
      <div className="md:hidden w-full gap-y-60px-mvw md:gap-y-0">
        <Slider {...slickSettings}>
          {data.map((imgSrc, index) => (
            <div
              key={index}
              className="w-full h-[80vw] flex justify-center items-center"
            >
              <div className="w-full h-full flex justify-center items-center">
                <img
                  src={imgSrc}
                  alt={`imgGallery-${index}`}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* For Desktop: Marquee */}
      {data.length > 3 ? (
        <div className="hidden md:grid grid-cols-1  gap-[10vw] place-items-center">
          <Marquee
            velocity={velocity}
            minScale={0.8}
            className="partner-marquee"
            pauseOnHover={isPaused}
          >
            {data.map((imgSrc, index) => (
              <div
                key={index}
                className="w-[24vw] h-[24vw] !mr-[6.25vw] flex items-center justify-center gap-[6.25vw]"
              >
                <img
                  src={imgSrc}
                  alt={`imgGallery-${index}`}
                  className="w-[24vw] h-[24vw]  object-contain"
                />
              </div>
            ))}
          </Marquee>
        </div>
      ) : (
        <div className="hidden w-full md:grid grid-cols-1 place-items-center">
          <div className="flex gap-[6.25vw] justify-center items-center flex-nowrap">
            {data.map((imgSrc, index) => (
              <div
                key={index}
                className="w-[24vw] h-[24vw] flex items-center justify-center"
              >
                <img
                  src={imgSrc}
                  alt={`imgGallery-${index}`}
                  className="w-[24vw] h-[24vw] object-contain"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
