import React, { useEffect, useState } from "react";
import CategoryTopSection from "../../category/CategoryTopSection";
import DownloadCard from "../../common/DownloadCard";

import Aos from "aos";
import { perimeter } from "../../../Data/RectinaGlaucoma";
import BottomSlider from "../../slider/BottomSlider";
import ImageSlider from "../../slider/ImageSlider";
import CategorySelector from "../../category/CategorySelector";
import Popup from "../../common/Popup";

const Perimeters = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const handleOpenModal = (pdfUrl) => {
    setSelectedPdf(pdfUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const activeCategory = perimeter[activeIndex];

  useEffect(() => {
    Aos.init({
      easing: "ease-in",
      duration: 1500,
      once: false,
    });
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="w-full relative">
      <CategoryTopSection
        breadcrumb={"Home | Products | Retina & Glaucoma | Perimeters"}
        centerHeading={"Perimeters"}
      />

      <section
        className="w-full  md:py-[7.81vw] pt-60px-mvw "
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-offset="150"
      >
        <CategorySelector
          categories={perimeter}
          activeCategoryIndex={activeIndex}
          onCategorySelect={(index) => setActiveIndex(index)}
        />

        {activeCategory.data.map((item) => (
          <div key={item.id} className="w-full">
            <div className="grid md:grid-cols-2 md:px-[7.29vw] px-16px-mvw gap-x-[6vw] grid-cols-1 gap-y-40px-mvw md:gap-y-0 py-40px-mvw md:py-0 md:pt-85px-vw md:pb-[7vw]">
              <div className="flex flex-col font-poppins text-[#2B2A29] items-start justify-start gap-y-16px-mvw md:gap-y-30px-vw">
                <div className="md:max-w-full md:max-h-[50px]  max-h-[35px] overflow-hidden">
                  <img
                    src={item.logo}
                    alt="logo"
                    className="w-full h-full object-contain block"
                  />
                </div>
                <div className="font-semibold md:text-48px-vw text-24px-mvw pt-14px-mvw md:pt-0">
                  {item.model}
                </div>
                <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw">
                  {item.name}
                </div>
                <div className="font-semibold md:text-32px-vw text-18px-mvw">
                  KEY FEATURES:
                </div>
                {item.main_points.map((item) => (
                  <div className="w-full flex items-center gap-x-8px-mvw md:gap-x-20px-vw">
                    <div className="bg-[#519A7F] rounded-full w-7px-mvw h-7px-mvw md:w-14px-vw md:h-14px-vw"></div>
                    <div className="font-medium md:text-24px-vw text-16px-mvw">
                      {item}
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-full flex items-start justify-center">
                {item.img.map((img, index) => (
                  <img
                    src={img}
                    alt=""
                    className="w-full max-h-full object-contain"
                  />
                ))}
              </div>
            </div>

            <h1 className="font-raleway px-16px-mvw md:px-0 text-left md:text-center font-bold pt-30px-mvw md:pt-0 text-24px-mvw md:text-32px-vw text-[#2B2A29]">
              PRODUCT KEY FEATURES
            </h1>

            <div className="w-full h-full md:px-[7.29vw] px-16px-mvw md:my-60px-vw pt-20px-mvw md:pt-0 pb-20px-mvw md:pb-0 grid md:grid-cols-3 gap-20px-vw relative">
              {/* Left Grid */}
              <div className="grid md:grid-cols-1 md:pr-60px-vw gap-20px-vw">
                {item.keyFeatureObject.key_feature
                  .slice(0, 3) // Always 3 items on the left grid
                  .map((data) => (
                    <div
                      key={data.id}
                      className="flex flex-col font-poppins text-[#2B2A29] md:pb-40px-vw pb-32px-mvw items-start justify-start"
                    >
                      <div className="flex items-center gap-x-20px-mvw md:gap-x-20px-vw">
                        <div className="bg-[#519A7F] flex flex-shrink-0 w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                          <img
                            src={data.img}
                            alt="icon"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="md:text-24px-vw text-18px-mvw font-semibold">
                          {data.title}
                        </div>
                      </div>
                      <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw md:pt-16px-vw leading-relaxed">
                        {data.desc}
                      </div>
                    </div>
                  ))}
              </div>

              {/* Center Image */}
              <div className="md:flex flex-col hidden gap-y-100px-vw items-center md:max-w-[29.74vw] md:max-h-[40.47vw] justify-center relative">
                <div className="">
                  <img
                    src={item.keyFeatureObject.commonImg}
                    alt="center"
                    className="w-full h-full object-contain"
                  />
                </div>

                {/* 7th Object under Image */}
                {item.keyFeatureObject.key_feature.length === 7 && (
                  <div
                    key={item.keyFeatureObject.key_feature[6].id}
                    className="w-full hidden md:flex flex-col items-center justify-center text-center"
                  >
                    <div className="bg-[#519A7F] w-60px-vw h-60px-vw p-12px-vw rounded-full mx-auto">
                      <img
                        src={item.keyFeatureObject.key_feature[6].img}
                        alt="icon"
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <div className="text-24px-vw font-semibold text-[#2B2A29]">
                      {item.keyFeatureObject.key_feature[6].title}
                    </div>
                    <div className="font-regular md:text-20px-vw opacity-80 md:max-w-[90%] text-16px-mvw pt-10px-vw leading-relaxed">
                      {item.keyFeatureObject.key_feature[6].desc}
                    </div>
                  </div>
                )}
              </div>

              {/* Right Grid */}
              <div className="grid md:grid-cols-1 md:pl-60px-vw gap-20px-vw">
                {item.keyFeatureObject.key_feature
                  .slice(3, 6) // Only 3 items on the right grid
                  .map((data) => (
                    <div
                      key={data.id}
                      className="flex flex-col font-poppins text-[#2B2A29] md:pb-40px-vw pb-32px-mvw items-start justify-start"
                    >
                      <div className="flex items-center gap-x-20px-mvw md:gap-x-20px-vw">
                        <div className="bg-[#519A7F] flex flex-shrink-0 w-48px-mvw h-48px-mvw md:w-60px-vw md:h-60px-vw md:p-12px-vw p-6px-mvw rounded-full">
                          <img
                            src={data.img}
                            alt="icon"
                            className="w-full h-full object-contain"
                          />
                        </div>
                        <div className="md:text-24px-vw text-18px-mvw font-semibold">
                          {data.title}
                        </div>
                      </div>
                      <div className="font-regular md:text-20px-vw text-16px-mvw opacity-80 md:pt-16px-vw pt-12px-mvw leading-relaxed">
                        {data.desc}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            {
              <div
                className="w-full md:px-[22vw] px-16px-mvw  gap-x-16px-vw gap-y-20px-mvw md:gap-y-0 md:pt-[3vw] pt-20px-mvw
               flex md:flex-row flex-col items-center justify-center"
              >
                <DownloadCard
                  data={item.additionalInfo}
                  onOpenModal={handleOpenModal}
                />
              </div>
            }

            {item?.video &&
              item?.video.map((item) => (
                <div className="w-full flex relative flex-col items-center justify-center md:pt-[7.81vw] pt-60px-mvw ">
                  <h1 className="font-raleway font-bold md:text-32px-vw pb-20px-mvw md:pb-0  text-24px-mvw text-[#2B2A29]">
                    PRODUCT GALLERY
                  </h1>

                  {/* Image Gallery Section */}

                  <ImageSlider data={item.imggallery} />

                  {/* Separator Line */}
                  {/* <div className="absolute left-0 md:w-[87%] md:mb-[7vw] md:mx-[7vw]  border-b-2 opacity-10 border-[#2B2A29] z-10"></div> */}

                  {/* <BottomSlider slides={item.videoData} /> */}
                </div>
              ))}
          </div>
        ))}
      </section> 
      {showModal && (
        <Popup
          showModal={showModal}
          selectedPdf={selectedPdf}
          handleCloseModal={handleCloseModal}
        />
      )}
    </main>
  );
};

export default Perimeters;
