import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import SectionHeading from "./SectionHeading";
import Button from "./Button";

const Popup = ({ showModal, selectedPdf, handleCloseModal }) => {
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone number is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Trigger the PDF download
      if (selectedPdf) {
        const link = document.createElement("a");
        link.href = selectedPdf;
        link.download = selectedPdf.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      handleCloseModal();
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   if (showModal) {
  //     document.documentElement.style.overflow = "hidden";
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.documentElement.style.overflow = "";
  //     document.body.style.overflow = "";
  //   }

  //   return () => {
  //     document.documentElement.style.overflow = "";
  //     document.body.style.overflow = "";
  //   };
  // }, [showModal]);

  if (!showModal) return null;

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full h-full flex items-center justify-center bg-[#2b2a29] bg-opacity-50">
      <div
        className=" p-8 rounded-lg md:w-[30vw] w-[90%]  shadow-lg relative"
        style={{
          backgroundImage: `url("/images/home/overall_bg.png")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          // minHeight: "100vh",
        }}
      >
        {/* Close Icon */}
        <button
          onClick={handleCloseModal}
          className="absolute top-4 right-4 text-[#2B2A29] text-28px-mvw md:text-32px-vw hover:text-red-500 transition-all"
        >
          <AiOutlineClose />
        </button>

        <div className="justify-self-start block md:mb-20px-vw mb-20px-mvw">
          <SectionHeading title={"Enter Your Details"} />
        </div>

        <form
          onSubmit={handleSubmit}
          className="md:space-y-30px-vw space-y-20px-mvw"
        >
          <div>
            <label className="font-poppins text-[#2B2A29] font-medium md:text-24px-vw text-18px-mvw">
              Name*
            </label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="block w-full px-0 pt-6px-mvw md:pt-10px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-20px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-20px-vw placeholder:font-regular focus:outline-none focus:ring-0"
              placeholder="Enter Your Name"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>

          <div>
            <label className="font-poppins text-[#2B2A29] font-medium md:text-24px-vw text-18px-mvw">
              Email*
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="block w-full px-0 pt-6px-mvw md:pt-10px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-20px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-20px-vw placeholder:font-regular focus:outline-none focus:ring-0"
              placeholder="Enter Your Email"
            />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>

          <div>
            <label className="font-poppins text-[#2B2A29] font-medium md:text-24px-vw text-18px-mvw">
              Phone Number*
            </label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="block w-full px-0 pt-6px-mvw md:pt-10px-vw pb-6px-mvw md:pb-10px-vw border-0 border-b-[1.5px] border-[#2B2A29] border-opacity-20 text-16px-mvw md:text-20px-vw bg-transparent placeholder:text-[#343B3C] placeholder:opacity-[0.43] placeholder:font-poppins placeholder:text-16px-mvw md:placeholder:text-20px-vw placeholder:font-regular focus:outline-none focus:ring-0"
              placeholder="Enter Your Phone Number"
            />
            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
          </div>

          <div className="text-left w-full md:mt-20px-vw">
            <Button
              title="Submit & Download"
              type="submit"
              className="text-white px-4 py-2 rounded"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Popup;
